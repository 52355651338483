<template>
    <div id="map">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="white"
          class="checkbox-overlay"
          v-bind="attrs"
          v-on="on"
          @click="zoomToExtent"
        >
          <v-icon>mdi-target</v-icon>
        </v-btn>
        </template>
        <span>Reset view to Poi extent</span>
      </v-tooltip>
            <v-btn
              rounded
              color="rgba(255, 255, 255, 0.6)"
              small
              class="map-footer"
              href="https://www.neoaxess.com/"
            >
            Powered by Neoaxess®
            </v-btn>
    </div>
</template>

<style scoped>
@import "~maplibre-gl/dist/maplibre-gl.css";

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}


.map-footer {
  z-index: 1;
  position: fixed;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

.checkbox-overlay {
  background-color: #fff;
  position: absolute;
  right: 0;
  margin-right: 9px;
  padding-right: 5px;
  top: 148px;
  z-index: 1;
  min-width: auto !important;
  width: 10px !important;
}

</style>

<script>
import {
  Map,
  FullscreenControl,
  AttributionControl,
  NavigationControl,
  ScaleControl,
  Popup
} from 'maplibre-gl'
import U from 'mapbox-gl-utils'

import MapPopup from '@/components/Popup.vue'
import { mapState, mapMutations } from 'vuex'
import {client} from '../utils'

export default {
  name: "MapComp",
  data () {
      return {
        map: null,
        featuresExtent: null
      }
  },
  computed: {
    ...mapState([
      'accessToken',
      'selectedItems',
      'sites',
      'mapViewLocked',
      'mapStyle',
    ]),
    navTree: {
      get() {return this.$store.navTree},
      set(value) {this.$store.commit('updateNavTree', value)},
    }
  },
  methods: {
    ...mapMutations([
      'loadingOn',
      'loadingOff',
      'lockMapView'
    ]),
    getGeoJson (val) {
      this.map.U.removeSource('locations')
      if (this.selectedItems.length > 0) {
        this.loadingOn()
        client.get('/accesspoints/geojson?uuid=' + val.join('&uuid='),
          {headers: {"Authorization": 'Bearer ' + this.accessToken}}
        ).then((response) => {
          this.addGeoJSON(response.data)
        })
        setTimeout(() => {
          this.loadingOff()
        }, 1500)
      }
    },
    setStyle () {
      this.map.setStyle(
        process.env.VUE_APP_TILES_BASE_URL + '/styles/' + this.mapStyle,
      )
      setTimeout(() => {
        this.getGeoJson(this.selectedItems)
      }, 3000)
    },
    addGeoJSON (geojson) {
      // ajout de la source puis du layer si layer non vide
      if (geojson.features.length == 0) {
        console.log('Empty geojson received')
        this.featuresExtent = null
        return;
      }

      this.map.U.addGeoJSON('locations', geojson,
        {
          cluster: true,
          clusterProperties: {
            "person_count": ["+", ["get", "person_count"]]
          }
        }
      )
        .addSymbolLayer('locations-layer',
        {
            'icon-image': 'bluemarker',
            'icon-size': 0.7,
            'icon-allow-overlap': true,
            'text-field': ['get', 'person_count'],
            'text-font': ['Roboto Black'],
            'text-offset': [0, -0.1],
            'filter': ['!has', 'point_count'],
        })
        .addSymbolLayer('locations-layer-clustered',
        {
            'icon-image': 'bluemarker-cluster',
            'icon-size': 0.7,
            'icon-allow-overlap': true,
            'text-field': ['get', 'person_count'],
            'text-font': ['Roboto Black'],
            'text-offset': [0, -0.1],
            'filter': ['has', 'point_count'],
        })

      this.map.on('click', 'locations-layer', (e) => {
        var coordinates = e.features[0].geometry.coordinates.slice();
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new Popup({'offset': 20, 'closeButton': false})
        .setLngLat(coordinates)
        .setHTML('<div id="vue-popup-content"></div>')
        .addTo(this.map);

        new MapPopup({
          propsData: { feature: e.features[0] },
          parent: this
        }).$mount('#vue-popup-content')

      });

      this.map.on('click', 'locations-layer-clustered', (e) => {
        var features = this.map.queryRenderedFeatures(e.point, {
          layers: ['locations-layer-clustered']
        });
        var clusterId = features[0].properties.cluster_id;
        this.map.getSource('locations').getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;
            this.map.flyTo({
              center: features[0].geometry.coordinates,
              zoom: zoom + 2
            });
          }
        );
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.on('mouseenter', 'locations-layer', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseenter', 'locations-layer-clustered', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'locations-layer', () => {
        this.map.getCanvas().style.cursor = '';
      });
      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'locations-layer-clustered', () => {
        this.map.getCanvas().style.cursor = '';
      });

      // store new extent
      this.featuresExtent = geojson.properties.bbox

      if (!this.mapViewLocked) {
        this.map.fitBounds(
          geojson.properties.bbox, {
            'maxZoom': 15,
            'padding': 50
          });
        // relock mapview
        this.lockMapView()
      }
    },
    zoomToExtent () {
      if (this.featuresExtent) {
        this.map.fitBounds(
          this.featuresExtent, {
            'maxZoom': 15,
            'padding': 50
          });
      }
    }
  },
  watch: {
    selectedItems: function(val) {
      //update map according to selected items
      this.getGeoJson(val)
    },
    sites: function() {
      //update map when sites changed
      this.loading = true
      setTimeout(() => {
        this.getGeoJson(this.selectedItems)
      }, 1000)
      this.loading = false
    },
    mapStyle: function() {
      this.setStyle()
    }
  },
  mounted() {
    this.map = new Map({
      container: "map",
      style: process.env.VUE_APP_TILES_BASE_URL + '/styles/'+ this.mapStyle,
      zoom: 8,
      // center on Paris
      center: [2.34, 48.85],
      attributionControl: false
    })
    U.init(this.map)

    this.map.addControl(new FullscreenControl());
    this.map.addControl(new AttributionControl({compact: true,}));
    this.map.addControl(new NavigationControl());
    this.map.addControl(new ScaleControl());
    this.map.setMaxBounds([[1.66, 48.46], [3.05, 49.23]])

    this.map.on('style.load', () => {
      this.map.loadImage(require('@/assets/public/markers/png/blue.png'),
        (error, image) => {
          if (error) throw error;
          this.map.addImage('bluemarker', image);
        }
      )
      this.map.loadImage(require('@/assets/public/markers/png/blue-cluster.png'),
        (error, image) => {
          if (error) throw error;
          this.map.addImage('bluemarker-cluster', image);
        }
      )
    })
  }
}

</script>
hyper
