<template>
<div>
	<TreeBar />
    <MapComp />
</div>
</template>

<script>
import MapComp from '@/components/Map.vue'
import TreeBar from '@/components/TreeBar.vue'

export default {
  components: {
    MapComp,
    TreeBar
  }
}
</script>
