<template>
  <!-- <div> -->
  <div>
  <v-navigation-drawer
      floating
      clipped
      disable-resize-watcher
      left
      v-if="sites"
      v-model="navBarTree"
      hide-overlay
      app
      width="auto"
      style="max-width: 500px"
    >

<!--
      <template v-slot:prepend>
        <v-card class="pa-2">
          <span>Liste des sites/zones/antennes</span>
        </v-card>
       </template> -->

        <v-treeview
          selectable
          dense
          :items="sites"
          v-model="selectedItems"
          item-disabled="locked"
        >
        <template v-slot:label="{ item }">
          {{item.name}}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.count > 0"
                text
                rounded
                light
                v-bind="attrs"
                v-on="on"
                @click="details(item)"
                href
              >
              <v-badge
                :content="item.person_count.toString()"
                v-if="item.person_count"
                color="indigo"
                offset-y="20"
                inline
                class="text-caption"
              ><v-icon>mdi-account-hard-hat</v-icon></v-badge>
              <v-badge
                :content="item.equipment_count.toString()"
                v-if="item.equipment_count"
                color="indigo"
                offset-y="20"
                inline
                class="text-caption"
              ><v-icon>mdi-tools</v-icon></v-badge>
              </v-btn>
            </template>
          <span>Download details</span>
          </v-tooltip>
          <v-tooltip right v-if="item.timeout">
                <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red">
                mdi-alert-circle
                </v-icon>
                </template>
            <span>Last Update: {{ item.blinktime}}</span>
          </v-tooltip>
        </template>
        </v-treeview>

    <v-spacer></v-spacer>
     <template v-slot:append>
      <div class="pa-4 text-caption">
        <v-divider></v-divider>
        <v-checkbox
            dense
            input-value="bool(polling)"
            :label="`Auto refresh : ${refreshRate / 1000}s`"
            @click="autoRefresh"
            class='text-caption'
        ></v-checkbox>
        <p>Last update: {{lastUpdate}}</p>
      </div>
     </template>
  </v-navigation-drawer>
</div>
</template>


<style>

.gothic {
  font-family: "HandelGothic BT" !important;
}

.v-navigation-drawer__content {
  overflow: auto;
}

.v-treeview-node__label {
  overflow: clip !important;
}

</style>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import {client} from '../utils'

export default {
  name: "TreeBar",
  data () {
      return {
        polling: null
      }
  },
  computed: {
    ...mapState([
      'accessToken',
      'currentUser',
      'isAdmin',
      'sites',
      'navTree',
      'lastUpdate',
      'refreshRate'
    ]),
    navBarTree: {
      get() {return this.navTree},
      set(value) {this.swapNavTree(value)},
    },
    selectedItems : {
      get() {
        return this.$store.state.selectedItems
      },
      set(value) {
        this.unlockMapView()
        this.updateSelectedItems(value)
      }
    }
  },
  methods: {
    ...mapMutations([
      'swapNavTree',
      'updateSelectedItems',
      'unlockMapView',
      'loadingOn',
      'loadingOff',
    ]),
    ...mapActions([
      'getSites',
    ]),
    updateData() {
      this.polling = setInterval(() => {
        this.getSites();
      }, this.refreshRate)
    },
    autoRefresh() {
      if (this.polling) {
        clearInterval(this.polling)
        this.polling = null
      } else {
        this.getSites()
        this.updateData()
      }
    },
    details(item) {
      this.loadingOn()
      client.get("/report/pdf?uuid=" + item.id,
        {
          responseType: 'blob',
          headers: {"Authorization": 'Bearer ' + this.accessToken}}
      ).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'report.pdf') //any other extension
        link.setAttribute('type', 'application/pdf') //any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.loadingOff()
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  mounted () {
    this.getSites();
    this.updateData();
  }
};
</script>
