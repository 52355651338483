<template>
<div>
    <h4>{{feature.properties.name}}</h4>
    <div>Persons: {{feature.properties.person_count}}</div>
    <div>Equipments: {{feature.properties.equipment_count}}</div>
    <div>Last update: {{feature.properties.blinktime}}</div>
    <v-btn
        small
        class="ma-2"
        @click="downloadpdf">
        <v-icon>mdi-file-pdf-outline</v-icon>pdf
    </v-btn>
    <v-btn
        small
        class="ma-2"
        @click="downloadcsv">
        <v-icon>mdi-file-excel-outline</v-icon>csv
    </v-btn>
</div>
</template>

<script>
// thanks to https://github.com/phegman/vue-mapbox-gl#popups
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import {client} from '../utils'

export default Vue.extend({
  name: "MapPopup",
  props: {
    feature: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState([
      'accessToken',
    ])
  },
  methods: {
    ...mapMutations([
      'loadingOn',
      'loadingOff',
    ]),
    downloadpdf() {
      this.loadingOn()
      client.get("/report/pdf?uuid=" + this.feature.properties.uuid,
        {
          responseType: 'blob',
          headers: {"Authorization": 'Bearer ' + this.accessToken}}
      ).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'report.pdf') //any other extension
        link.setAttribute('type', 'application/pdf') //any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.loadingOff()
      })
    },
    downloadcsv() {
      this.loadingOn()
      client.get("/report/csv?uuid=" + this.feature.properties.uuid,
        {
          responseType: 'blob',
          headers: {"Authorization": 'Bearer ' + this.accessToken}}
      ).then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'report.csv') //any other extension
        link.setAttribute('type', 'application/csv') //any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.loadingOff()
      })
    },
  },
})
</script>
