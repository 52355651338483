<template>
   <v-container fluid fill-height>
      <v-layout align-center justify-center>
         <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
               <v-card-text>
                  <v-form
                    @submit.prevent="loginSubmit"
                    ref="form"
                    v-model="valid">
                     <v-text-field
                        v-model="username"
                        prepend-icon="person"
                        name="username"
                        label="Username"
                        type="text"
                        :rules="usernameRules"
                        required
                     ></v-text-field>
                     <v-text-field
                        v-model="password"
                        id="password"
                        prepend-icon="lock"
                        name="password"
                        :rules="passwordRules"
                        label="Password"
                        type="password"
                        required
                     ></v-text-field>
                        <v-btn
                            type="submit"
                            color="indigo darken-4"
                            class="mt-4"
                            block
                            :disabled="!valid">
                           <v-icon left class="white--text">login</v-icon>
                           <span class="white--text pt-1">Login</span>
                        </v-btn>
                     <v-spacer></v-spacer>

                      <v-alert class="mt-6"
                          transition="fade-transition"
                          type="error"
                          outlined
                          v-if="loginError">
                        {{ loginError }}
                      </v-alert>
                      <v-alert class="mt-6"
                          transition="fade-transition"
                          outlined
                          type="success"
                          v-if="accessToken">
                        Login Successfull
                    </v-alert>

                    </v-form>
               </v-card-text>
            </v-card>
         </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
//https://webdevchallenges.com/persist-access-token-with-vue-js/
import { mapState, mapActions } from 'vuex';

export default {
   name: 'Login',
   data: () => (
      {
         valid: false,
         username: '',
         usernameRules: [
           v => !!v || 'Username is required',
           v => (v && v.length >= 3) || 'Username must be at least 3 characters',
         ],
         password: '',
         passwordRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 8) || 'Password must be at least 8 characters',
         ],
      }
    ),
    computed: {
      ...mapState([
        'LoggingIn',
        'loginError',
        'accessToken'
      ])
    },
    methods: {
      ...mapActions([
        'doLogin'
      ]),
      loginSubmit() {
        this.doLogin({
          username: this.username,
          password: this.password
        });
      }
    },
    beforeMount() {
      if (this.accessToken) {
        this.$router.push({ name: 'Home' })
    }
}
};
</script>
