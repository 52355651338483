var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.sites)?_c('v-navigation-drawer',{staticStyle:{"max-width":"500px"},attrs:{"floating":"","clipped":"","disable-resize-watcher":"","left":"","hide-overlay":"","app":"","width":"auto"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-4 text-caption"},[_c('v-divider'),_c('v-checkbox',{staticClass:"text-caption",attrs:{"dense":"","input-value":"bool(polling)","label":("Auto refresh : " + (_vm.refreshRate / 1000) + "s")},on:{"click":_vm.autoRefresh}}),_c('p',[_vm._v("Last update: "+_vm._s(_vm.lastUpdate))])],1)]},proxy:true}],null,false,269278153),model:{value:(_vm.navBarTree),callback:function ($$v) {_vm.navBarTree=$$v},expression:"navBarTree"}},[_c('v-treeview',{attrs:{"selectable":"","dense":"","items":_vm.sites,"item-disabled":"locked"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.count > 0)?_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","rounded":"","light":"","href":""},on:{"click":function($event){return _vm.details(item)}}},'v-btn',attrs,false),on),[(item.person_count)?_c('v-badge',{staticClass:"text-caption",attrs:{"content":item.person_count.toString(),"color":"indigo","offset-y":"20","inline":""}},[_c('v-icon',[_vm._v("mdi-account-hard-hat")])],1):_vm._e(),(item.equipment_count)?_c('v-badge',{staticClass:"text-caption",attrs:{"content":item.equipment_count.toString(),"color":"indigo","offset-y":"20","inline":""}},[_c('v-icon',[_vm._v("mdi-tools")])],1):_vm._e()],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download details")])]),(item.timeout)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("Last Update: "+_vm._s(item.blinktime))])]):_vm._e()]}}],null,false,4144256333),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('v-spacer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }